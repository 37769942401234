import React, { useState } from 'react';
import { Card, CardBody, Col } from 'reactstrap';
import {
  BarChart, Bar, Cell, ResponsiveContainer,
} from 'recharts';
import TrendingUpIcon from 'mdi-react/TrendingUpIcon';
import Http from "../../../App/Http";
import ModalCreateVenue from './ModalCreateVenue';

const CreateVenue = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  // const activeItem = data[activeIndex];

  // const getWeeklySubmissionNumbers = () => {
  //     return Http.post("/w/getWeeklySubmissionNumbers", null).then(response => {
  //             if (response.data.status == 'failed') {
  //                 return false;
  //             }
  //
  //             debugger
  //             return true;
  //         });
  // };

  const handleClick = (item) => {
    const index = data.indexOf(item.payload);
    setActiveIndex(index);
  };

  return (
    <Col md={12} xl={3} lg={6} xs={12}>
      <Card>
        <CardBody className="dashboard__card-widget">
            <div className="card__title">
                <h5 className="bold-text">Create Venue</h5>
            </div>
            <ModalCreateVenue />
        </CardBody>
      </Card>
    </Col>
  );
};

export default CreateVenue;
