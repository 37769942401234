import React, {useEffect, useState} from 'react';
import { Col, Container, Row } from 'reactstrap';
import ModelVenue from '../../Models/Venue';
import ActionClicks from './components/ActionClicks';
import SubmissionsTable from './components/SubmissionsTable';
import DatepickerField from './components/DatepickerField';
import VenueTier from './components/VenueTier/VenueTier';
import VenueStatus from './components/VenueStatus/VenueStatus';
import PageViews from './components/PageViews';
import { withRouter, useParams } from 'react-router-dom';
import {setVenueStats,setVenueData, getVenueData, setDateRangeEnding, setDateRangeStarting, showVenueMenu} from '../../redux/actions/venueActions';
import {connect} from "react-redux";
import TransactionsTable from "./components/Transactions/TransactionsTable";

const Venue = ({ dispatch, dateRangeEnding, dateRangeStarting, venueData, venueStats }) => {
    const { venueId } = useParams();
    const [isLoaded, setIsLoaded] = useState(false);
    const vm = new ModelVenue();

    useEffect(() => {
        dispatch(showVenueMenu(true));

        vm.getVenueData(venueId).then(response => {

            if (response.data.status === 'failed') {
                alert('failed');
                return false;
            }
            return true;
        });

        vm.getVenueStats(venueId,dateRangeStarting,dateRangeEnding).then(response=>{
            if (response.data.status === 'failed') {
                alert('failed');
                return false;
            }

            dispatch(setVenueStats(response.data));
            setIsLoaded(true);
            return true;
        });



    },[venueId,dateRangeEnding,dateRangeStarting]);

    const updateDateRangeStarting = (date,d) => {
        dispatch(setDateRangeStarting(date));
    };

    const updateDateRangeEnding = (date,d) => {
        dispatch(setDateRangeEnding(date));
    };

    return (
        isLoaded && (
            <Container className="dashboard">
                <Row className={"pb-2"}>
                    <Col md={6}>
                        <h3 className="page-title">{venueData.v_name}</h3>
                    </Col>
                    <Col xs={6} md={6} className={'text-right'}>
                        <DatepickerField
                            name="From"
                            dateFormat="MM/dd/yyyy"
                            onChange={updateDateRangeStarting}
                            selected={dateRangeStarting}
                        />
                        <DatepickerField
                            name="To"
                            dateFormat="MM/dd/yyyy"
                            onChange={updateDateRangeEnding}
                            selected={dateRangeEnding}
                        />
                    </Col>
                </Row>
                <Row>
                    <VenueTier venueInfo={ venueData }/>
                    <VenueStatus venueInfo={ venueData }/>
                </Row>
                <Row>
                    <PageViews pageViewsData={ venueStats.pageViewsData }/>
                    <ActionClicks eventsData={ venueStats.eventsData } />
                    <SubmissionsTable submissionsData={ venueStats.submissions }/>
                    <TransactionsTable />
                </Row>
            </Container>
        )
    );
}

export default connect(state => ({
    dateRangeEnding: state.venue.dateRangeEnding,
    dateRangeStarting:state.venue.dateRangeStarting,
    venueData:state.venue.venueData,
    venueStats:state.venue.venueStats,
}))(Venue);