import React,{ useEffect,useState } from 'react';
import Panel from "../../../shared/components/Panel";
import DataReactTable from "./DataReactTable";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {connect} from "react-redux";
import ModelVenues from '../../../Models/Venues';

const VenuesTableV2 = ({ dispatch, venues }) => {

    const history = useHistory();
    const [isTableLoaded,setLoaded] = useState(false);
    const vsm = new ModelVenues();

    const redirect = (myKey) =>{
        history.push(`/venue/${myKey}`);
    };

    const fetchData = async () => {
        setLoaded(false);
        vsm.getVenues().then(response=>{
            if (!response) {
                alert('failed to load venues');
                return false;
            }
            setLoaded(true);
            return true;
        });
    };

    useEffect(()=>{
        fetchData().then(response => console.log("responded"));
    },[]);

    const columns1 =[
        {
            width: 300,
            Header: "View/Edit",
            Cell: ({ cell }) => {
                // debugger
                return (
                    <button type={"button"} className={ "btn btn-primary btn-sm m-0 mr-2 pt-1 pb-1 pl-2 pr-2"} onClick={() => {redirect(cell.row.values.v_id)}}>
                        View/Edit
                    </button>
                )
            }
        },{
            Header: 'Name',
            accessor: 'v_name',
            width: 150
        },{
            Header: 'Tier',
            accessor: 'vti_name'
        },{
            width: 300,
            Header: "Active",
            Cell: ({ cell }) => {
                if(cell.row.original.v_active == "1")
                    return (<h4><FontAwesomeIcon icon="check-circle" style={{color:'#adff2f'}}/></h4>);
                else
                    return (<h4><FontAwesomeIcon icon="times-circle" style={{color:'#FF0000'}}/></h4>);
            }
        },{
            width: 300,
            Header: "Has Contacts",
            Cell: ({ cell }) => {
                if(parseFloat(cell.row.original.vc_count) > 0)
                    return (<h4><FontAwesomeIcon icon="check-circle" style={{color:'#adff2f'}}/></h4>);
                else
                    return (<h4><FontAwesomeIcon icon="times-circle" style={{color:'#FF0000'}}/></h4>);
            }
        },{
            Header: 'Max Cap',
            accessor: 'v_max_capacity'
        },{
            Header: 'City',
            accessor: 'v_city'
        },{
            Header: 'Phone',
            accessor: 'v_phone'
        },{
            Header: 'Venue ID',
            accessor: 'v_id'
        },{
            Header: 'Created',
            accessor: 'v_created_date'
        }
    ];

    const tableStructure = {
        tableHeaderData: columns1,
        tableRowsData: venues
    };

    return (

        <Panel xl={12} lg={12} md={12} title="Venues" subhead="Venues" refreshFunction={fetchData}>
            <div>
                {isTableLoaded &&
                    <DataReactTable reactTableData={ tableStructure } />
                }
            </div>
        </Panel>

    );
};

export default connect(state => ({
    venues:state.venues.venues
}))(VenuesTableV2);
