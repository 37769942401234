import Http from '../containers/App/Http';
import store from '../containers/App/store'
import {setUser} from "../redux/actions/userActions";

export default class User{

    getUser(userId){
        // FETCH THE Users
        const formData = new FormData();
        formData.append('userId',userId);

        return Http.post("/u/getUser",formData).then(async response => {
            if (response.data.status === 'failed')
                return response;

            await store.dispatch(setUser(response.data.data));
            return response;
        });
    };

    createNewUser(formData){
        // formData.append('queryPageIndex',queryPageIndex);
        // formData.append('queryPageSize',queryPageSize);

        return Http.post("/u/createNewUser",formData).then(async response => {
            if (response.data.status === 'failed')
                return response;

            return response;
        });
    };

    updateUser(formData){
        // formData.append('queryPageIndex',queryPageIndex);
        // formData.append('queryPageSize',queryPageSize);

        return Http.post("/u/updateUser",formData).then(async response => {
            if (response.data.status === 'failed')
                return response;

            return response;
        });
    };

}