import React, {useEffect, useState} from 'react';
import { Col, Container, Row } from 'reactstrap';
import ModelUser from '../../Models/User';
import ActionClicks from './components/ActionClicks';
import SubmissionsTable from './components/SubmissionsTable';
import DatepickerField from './components/DatepickerField';
import VenueTier from './components/VenueTier/VenueTier';
import VenueStatus from './components/VenueStatus/VenueStatus';
import PageViews from './components/PageViews';
import { withRouter, useParams } from 'react-router-dom';
import {setVenueStats,setVenueData, getVenueData, setDateRangeEnding, setDateRangeStarting, showVenueMenu} from '../../redux/actions/venueActions';
import {connect} from "react-redux";
import TransactionsTable from "./components/Transactions/TransactionsTable";
import {showNotification} from "../../shared/components/Notification";

const User = ({ dispatch,user,theme,rtl}) => {
    const { userId } = useParams();
    const [isLoaded, setIsLoaded] = useState(false);
    const mu = new ModelUser();

    useEffect(() => {

        mu.getUser(userId).then(response => {

            if (response.data.status === 'failed') {
                alert('failed');
                return false;
            }
            setIsLoaded(true);
            return true;
        });

    },[userId]);

    useEffect(()=>{
        if(user===null)
            return;

        Object.keys(user).forEach(key => {
            document.querySelectorAll('input[name='+key+']').forEach((el)=>{
                el.value = user[key];
            });
            document.querySelectorAll('textarea[name='+key+']').forEach((el)=>{
                el.value = user[key];
            });
        });
    },[isLoaded]);

    const updateUserData = (e) =>{
        e.preventDefault();
        let formData = new FormData(document.getElementById('updateUserDataForm'));

        mu.updateUser(formData).then(response => {
            if (response.data.status === 'failed') {
                // setError(response.data.msg);
                // setSuccess(false);
                showNotification(theme,'danger',rtl,'User Update',response.data.msg);

                return false;
            }
            showNotification(theme,'info',rtl,'User Update',response.data.msg);
            // setError(false);
            // setSuccess(response.data.msg);
            // mu.getUsers().then(response=>{
            //     if (!response) {
            //         alert('failed to load users');
            //         return false;
            //     }
            //     return true;
            // });
        });
    }

    const setNewPasswordForUser = (e) =>{
        e.preventDefault();

    }

    const sendPasswordResetForUser = (e) =>{
        e.preventDefault();

    }

    return (
        isLoaded && (
            <Container className="dashboard">
                <Row className={"pb-1"}>
                    <Col xs={12}>
                        <h3>User</h3>
                        <hr/>
                    </Col>
                </Row>
                <form id="updateUserDataForm" onSubmit={updateUserData}>
                    <Row>
                        <Col xs={12} sm={6}>
                            <div className="form__form-group ">
                                <label htmlFor="u_id" className=" form__form-group-label col-form-label">ID</label>
                                <div className="">
                                    <input type="text" className="form-control form-control-sm" id="u_id" name="u_id" value={user.u_id} readOnly={true}/>
                                </div>
                            </div>
                            <div className="form__form-group ">
                                <label htmlFor="u_first" className="form__form-group-label col-form-label">First Name</label>
                                <div className="">
                                    <input type="text" className="form-control form-control-sm" id="u_first" name="u_first"/>
                                </div>
                                <label htmlFor="u_last" className="form__form-group-label col-form-label">Last Name</label>
                                <div className="">
                                    <input type="text" className="form-control form-control-sm" id="u_last" name="u_last" />
                                </div>
                            </div>

                        </Col>
                        <Col xs={12} sm={6}>
                            <div className="form__form-group ">
                                <label htmlFor="u_email" className="form__form-group-label col-form-label">Email</label>
                                <div className="">
                                    <input type="text" className="form-control form-control-sm" id="u_email" name="u_email" />
                                </div>
                                <label htmlFor="u_main_phone" className="form__form-group-label col-form-label">Phone</label>
                                <div className="">
                                    <input type="text" className="form-control form-control-sm" id="u_main_phone" name="u_main_phone" />
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <div className={'col-12 text-right'}>
                            <button className="btn btn-primary btn-sm" type="submit">Update</button>
                        </div>
                    </Row>
                </form>
                <Row>
                    <Col xs={12}>
                        <h3>Security</h3>
                        <hr/>
                    </Col>
                    <Col xs={12}>
                        <h4>Coming Soon!</h4>
                    </Col>
                    <Col xs={12}>
                        {/*<button type={"button"} className="btn btn-info btn-sm">Send Password Reset Email</button>*/}
                        {/*<h4>OR</h4>*/}
                        {/*<form id="setNewPasswordForUser"  onSubmit={setNewPasswordForUser}>*/}
                        {/*    <div className="form__form-group ">*/}
                        {/*        <label htmlFor="setNewPassword" className="form__form-group-label col-form-label">Set New Password</label>*/}
                        {/*        <div className="">*/}
                        {/*            <input type="text" className="form-control form-control-sm" id="u_email" name="setNewPassword" />*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*    <button type={"submit"} className="btn btn-info btn-sm">Change password from here</button>*/}
                        {/*</form>*/}
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <h3>Venue Access</h3>
                        <hr/>
                    </Col>
                    <Col xs={12}>
                        <h4>Coming Soon!</h4>
                    </Col>
                </Row>
            </Container>
        )
    );
}

export default connect(state => ({
    user:state.user.user,
    venueData:state.venue.venueData,
    rtl: state.rtl,
    venueStats:state.venue.venueStats,
    theme: state.theme,
}))(User);