import React, {useEffect, useState} from 'react';
import { Col, Container, Row, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import ModelVenue from '../../../../Models/Venue';
import { useParams } from 'react-router-dom';
import { getVenueData ,showVenueMenu} from '../../../../redux/actions/venueActions';
import classnames from 'classnames';
import {connect} from "react-redux";
import {showNotification} from "../../../../shared/components/Notification";

const VenueGoogleIntegration = ({ dispatch, venueData,rtl,theme }) => {
    const { venueId } = useParams();
    const [isLoaded, setIsLoaded] = useState(false);

    const [indexingNotification, setIndexingNotification] = useState(false);
    const [notificationSuccess, setNotificationSuccess] = useState(false);
    const [indexingNotificationError, setIndexingNotificationError] = useState(false);

    const [indexingRequestResult, setIndexingRequestResult] = useState(false);
    const [IRError, setIRError] = useState(false);
    const [IRSuccess, setIRSuccess] = useState(false);

    const vm = new ModelVenue();

    // create url
    let link = venueData.v_name.replace(/[^a-zA-Z0-9 ]/g, "");
    link = link.replace(/\s/g , "-");
    let venueName= encodeURIComponent(link) + "-"+venueData.v_id;
    let url = "https://bookwny.com/"+venueData.v_city.toLowerCase()+"-"+venueData.v_state.toLowerCase()+"/"+venueName;

    useEffect(() => {
        requestVenueIndexingNotifications();
    },[]);

    const requestVenueIndexing=()=>{

        let formData = new FormData();
        formData.append('url',url);

        vm.requestVenueIndexing(venueData.v_id,formData).then(async response=>{
            if (response.data.status === 'failed') {
                showNotification(theme,'danger',rtl,'Request Indexing',response.data.msg);
                return false;
            }
            await setIndexingRequestResult(response.data.data);
            if(response.data.data.hasOwnProperty('error')){
                setIRError(true);
                setIRSuccess(false);
            }else{
                setIRSuccess(true);
                setIRError(false);
            }
        });
    };

    const requestVenueIndexingNotifications=()=>{

        let formData = new FormData();
        formData.append('url',url);

        vm.requestVenueIndexingNotifications(venueData.v_id,formData).then(async response=>{
            if (response.data.status === 'failed') {
                showNotification(theme,'danger',rtl,'Indexing Notification',response.data.msg);
                return false;
            }
            await setIndexingNotification(response.data.data);
            if(response.data.data.hasOwnProperty('error')){
                setIndexingNotificationError(true);
                setNotificationSuccess(false);
            }else{
                setNotificationSuccess(true);
                setIndexingNotificationError(false);
            }
        });
    };

    return (

        <Col xs={'12'}>
            <Row>
                <Col xs={'12'}>
                    <h3>URL: <a href={url} target="_blank">{url}</a></h3>
                    <hr/>
                    <button type="button" onClick={requestVenueIndexing} className="btn btn-primary btn-sm">Request Indexing</button>
                    <button type="button" onClick={requestVenueIndexingNotifications} className="btn btn-primary btn-sm">Request Notification</button>
                </Col>
            </Row>

            <Row>
                <Col xs={'12'}>
                    <h3>Google Indexing Notifications</h3>
                    <hr/>
                    {indexingNotificationError===true &&
                        <>
                            <h4>Error Code: {indexingNotification.error.code}</h4>
                            <h4>Error Message: {indexingNotification.error.message}</h4>
                            <h4>Error Status: {indexingNotification.error.status}</h4>
                        </>
                    }
                    {notificationSuccess===true &&
                        <>
                            <h4>URL: {indexingNotification.url}</h4>
                            <h4>Type: {indexingNotification.latestUpdate.type}</h4>
                            <h4>Date and Time: {indexingNotification.latestUpdate.notifyTime}</h4>
                        </>
                    }
                </Col>
            </Row>

            <Row className={'mt-4'}>
                <Col xs={'12'}>
                    <h3>Google Indexing Request</h3>
                    <hr/>
                    {IRError===true &&
                        <>
                            <h4>Error Code: {indexingRequestResult.error.code}</h4>
                            <h4>Error Message: {indexingRequestResult.error.message}</h4>
                            <h4>Error Status: {indexingRequestResult.error.status}</h4>
                        </>
                    }
                    {IRSuccess===true &&
                        <>
                            <h4>URL: {indexingRequestResult.urlNotificationMetadata.url}</h4>
                            <h4>Type: {indexingRequestResult.urlNotificationMetadata.latestUpdate.type}</h4>
                            <h4>Date and Time: {indexingRequestResult.urlNotificationMetadata.latestUpdate.notifyTime}</h4>
                        </>
                    }
                </Col>
            </Row>
        </Col>

    );
}

export default connect(state => ({
    rtl: state.rtl,
    theme: state.theme,
    venueData:state.venue.venueData,
    showVenueMenu:state.venue.showVenueMenu
}))(VenueGoogleIntegration);