import React, {useEffect, useState} from 'react';
import { Col, Container , Row ,Card, CardBody, Progress, Table} from 'reactstrap';
import TotalSubmissions from './components/TotalSubmissions';
import {connect} from "react-redux";
import CreateVenue from "./components/CreateVenue/CreateVenue";
import VenuesTableV2 from './components/VenuesTableV2';
import {showVenueMenu} from "../../redux/actions/venueActions";
import SubmissionsTable from "../../shared/components/SubmissionsTable";
import NotesTable from "../../shared/components/NotesTable";
import UsersTable from "../../shared/components/UsersTable";

const Dashboard = ({ dispatch, venues }) => {

    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        dispatch(showVenueMenu(false));
        setIsLoaded(true);
    },[]);

    return (
        isLoaded && (
            <Container className="dashboard">
                <Row>
                    <Col xs={12} sm={6}>
                        <SubmissionsTable title="Latest Submissions" limit="20"/>
                    </Col>
                    <Col xs={12} sm={6}>
                        <NotesTable title="Latest Submission Notes" limit="20"/>
                    </Col>
                    <Col xs={12} sm={6}>
                        <UsersTable title="Newest Users" limit="20"/>
                    </Col>
                </Row>

                <Row>

                </Row>
                {/*<TotalSubmissions/>*/}
                {/*<CreateVenue />*/}
                {/*<VenuesTableV2 />*/}
            </Container>
        )
    );
};

export default connect(state => ({
    venues:state.venues.venues
}))(Dashboard);