import React,{ useEffect,useState } from 'react';
import DataReactTable from "./DataReactTable";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {connect} from "react-redux";
import ModelVenue from '../../../../Models/Venue';
import { faUserSlash } from '@fortawesome/fontawesome-free-solid';
import {showNotification} from "../../../../shared/components/Notification";
import {
    faEnvelopeOpen,
    faEnvelopeOpenText,
    faPen,
    faPhone,
    faPhoneSlash,
    faSms
} from "@fortawesome/free-solid-svg-icons";
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap.css';
import ModalViewEditUser from "./components/ViewEditUser/ModalViewEditUser";

const MembersTable = ({ dispatch, venueMembers ,venueData,theme,rtl}) => {

    const [isTableLoaded,setLoaded] = useState(false);
    const [user,setSelectedUser] = useState(null);
    const vm = new ModelVenue();

    useEffect(()=>{
        setLoaded(false);
        if(venueMembers !== null)
            setLoaded(true);

    },[venueMembers]);

    const removeMemberAccess = (member) => {
        let formData = new FormData();
        formData.append('userRoleId',member.ur_id);
        formData.append('venueId',member.v_id);
        formData.append('userId',member.u_id);

        vm.removeMemberAccess(formData).then(response => {
            if(response.data.status==='failed'){
                showNotification(theme,'danger',rtl,'Remove Access',response.data.msg);
                return;
            }
            showNotification(theme,'primary',rtl,'Remove Access',response.data.msg);

            vm.getVenueData(venueData.v_id).then(response=>{
                if (!response) {
                    showNotification(theme,'danger',rtl,'Members',response.data.msg);
                    return false;
                }
                return true;
            });

        });
    };

    const handleEdit = async (user) => {
        await setSelectedUser(false);
        await setSelectedUser(user);
    };

    const columns =[
        {
            width: 300,
            Header: "Options",
            Cell: ({ cell }) => {
                 // debugger
                return (
                    <>
                        <Tooltip placement="top" overlay={"Remove"}>
                            <a href={"/s/"+cell.row.original.qr_id} className={ "btn btn-danger btn-sm m-0 mr-2 pt-1 pb-1 pl-2 pr-1"} onClick={e => {e.preventDefault(); removeMemberAccess(cell.row.original)}}>
                                <FontAwesomeIcon icon={faUserSlash}/>
                            </a>
                        </Tooltip>
                        <Tooltip placement="top" overlay={"View/Edit"}>
                            <button type={"button"} className={ "btn btn-info btn-sm m-0 mr-2 pt-1 pb-1 pl-2 pr-1"} onClick={e => handleEdit(cell.row.original)}>
                                <FontAwesomeIcon icon={faPen}/>
                            </button>
                        </Tooltip>
                    </>
                )
            }
        },
        {
            Header: 'Role',
            accessor: 'r_display_name'
        },{
            Header: 'First',
            accessor: 'u_first',
            width: 150
        },{
            Header: 'Last',
            accessor: 'u_last'
        },{
            Header: 'Email',
            accessor: 'u_email'
        },{
            width: 100,
            Header: "Tags",
            Cell: ({ cell }) => {
                console.log(cell.row.original.ud_phone)
                const isDndPhone = (cell.row.original.ud_phone==1)?true:false;
                const isDndEmail = (cell.row.original.ud_email==1)?true:false;
                const isDndSms = (cell.row.original.ud_sms==1)?true:false;
                return (
                    <>
                        {isDndPhone &&
                        <Tooltip placement="top" overlay={"DND Phone"}>
                            <button type="button" className={"btn-danger btn btn-sm m-0 mr-2 pt-1 pb-1 pl-2 pr-1"}>
                                <FontAwesomeIcon icon={isDndPhone ? faPhoneSlash : faPhone} />
                            </button>
                        </Tooltip>
                        }
                        {isDndEmail &&
                        <Tooltip placement="top" overlay={"DND E-Mail"}>
                            <button type="button" className={"btn-danger btn btn-sm m-0 mr-2 pt-1 pb-1 pl-2 pr-1"}>
                                {/* Assuming you want a different icon for email here */}
                                <FontAwesomeIcon icon={faEnvelopeOpen} />
                            </button>
                        </Tooltip>
                        }
                        {isDndSms &&
                        <Tooltip placement="top" overlay={"DND SMS"}>
                            <button type="button" className={"btn-danger btn btn-sm m-0 mr-2 pt-1 pb-1 pl-2 pr-1"}>
                                {/* Assuming you want a different icon for SMS here */}
                                <FontAwesomeIcon icon={faSms} />
                            </button>
                        </Tooltip>
                        }
                    </>
                );

            }
        },{
            Header: 'Phone',
            accessor: 'u_main_phone'
        }
    ];

    const tableStructure= {
        tableHeaderData: columns
    };

    return (
        <div>
            {isTableLoaded &&
                <DataReactTable reactTableData={ tableStructure } />
            }
            {user &&
                <ModalViewEditUser user={user}/>
            }
        </div>
    );
};

export default connect(state => ({
    venueData:state.venue.venueData,
    venueMembers:state.venue.members,
    rtl: state.rtl,
    theme: state.theme
}))(MembersTable);
