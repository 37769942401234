import React,{ useEffect,useState } from 'react';
import Panel from "../../../../shared/components/Panel";
import DataReactTable from "./DataReactTable";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {connect} from "react-redux";
import ModelUsers from '../../../../Models/Users';
import ModalViewEditUser from "../ViewEditUser/ModalViewEditUser";
import {faExternalLinkAlt, faPen, faPenFancy, faPenSquare} from "@fortawesome/free-solid-svg-icons";
import Tooltip from "rc-tooltip";
import {Link, useHistory} from "react-router-dom";

const UsersTableV2 = ({ dispatch, venues,users }) => {

    const [isTableLoaded,setLoaded] = useState(false);
    const [user,setSelectedUser] = useState(null);
    const um = new ModelUsers();
    const history = useHistory();

    const redirect = (myKey) =>{
        history.push(`/u/${myKey}`);
    };

    const fetchData = async () => {
        um.getUsers().then(response=>{
            if (!response) {
                alert('failed to load users');
                return false;
            }
            return true;
        });
    };

    useEffect(()=>{
        setLoaded(false);
        if(typeof users !== 'undefined' || users!==null){
            setLoaded(true);
        }
    },[users]);

    useEffect(()=>{
        fetchData().then(response => console.log("responded"));
    },[]);

    const handleEdit = async (user) => {
        await setSelectedUser(false);
        await setSelectedUser(user);
    };

    const columns1 =[
        {
            width: 200,
            Header: "Edit",
            Cell: ({ cell }) => {
                 // debugger
                return (
                    <>
                        <Tooltip placement="top" overlay={"View/Edit"}>
                            <button type={"button"} className={ "btn btn-info btn-sm m-0 mr-2 pt-1 pb-1 pl-2 pr-1"} onClick={() => redirect(cell.row.original.u_id)}>
                                <FontAwesomeIcon icon={faPen}/>
                            </button>
                        </Tooltip>
                        <Tooltip placement="top" overlay={"View/Edit New Tab"}>
                            <Link
                                to={"/u/" + cell.row.original.u_id}
                                target="_blank"
                            >
                                <button type={"button"} className={ "btn btn-info btn-sm m-0 mr-2 pt-1 pb-1 pl-2 pr-1"}>
                                    <FontAwesomeIcon icon={faExternalLinkAlt} />
                                </button>
                            </Link>
                        </Tooltip>
                    </>
                )
            }
        },{
            Header: 'First Name',
            accessor: 'u_first',
            width: 150
        },
        {
            Header: 'Last Name',
            accessor: 'u_last'
        },{
            Header: 'Email',
            accessor: 'u_email'
        },{
            Header: 'Phone',
            accessor: 'u_main_phone'
        }
    ];

    const tableStructure= {
        tableHeaderData: columns1
    };

    return (

        <Panel xl={12} lg={12} md={12} title="Users" subhead="Users" refreshFunction={fetchData}>
            <div>
                {isTableLoaded &&
                    <DataReactTable reactTableData={ tableStructure } />
                }
                {user &&
                    <ModalViewEditUser user={user}/>
                }

            </div>
        </Panel>

    );
};

export default connect(state => ({
    venues:state.venues.venues,
    users:state.users.users
}))(UsersTableV2);
