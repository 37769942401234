import React,{ useEffect,useState } from 'react';
import Panel from "../Panel";
import DataReactTable from "./DataReactTable";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {connect} from "react-redux";
import ModelSubmissions from '../../../Models/Submissions';
import {Link, useHistory} from "react-router-dom";
import {faExternalLinkAlt, faEye, faPen} from "@fortawesome/free-solid-svg-icons";
import Tooltip from "rc-tooltip";

const SubmissionsTable = ({ dispatch, venues, title, subhead, limit}) => {

    const [isTableLoaded,setLoaded] = useState(false);
    const [submission,setSelectedSubmission] = useState(null);
    const [notes,setNotes] = useState(null);
    const um = new ModelSubmissions();
    const history = useHistory();

    const redirect = (link) =>{
        history.push(link);
    };

    const fetchData = async () => {
        um.getNotes(limit).then(response=>{
            if (!response) {
                alert('failed to load submissions');
                return false;
            }
            setNotes(response.data.data);
            setLoaded(true);

            return true;
        });
    };

    // useEffect(()=>{
    //     setLoaded(false);
    //     if(typeof submissions !== 'undefined' || submissions!==null){
    //         setLoaded(true);
    //     }
    // },[submissions]);

    useEffect(()=>{
        fetchData().then(response => console.log("responded"));
    },[]);

    const handleEdit = async (submission) => {
        await setSelectedSubmission(false);
        await setSelectedSubmission(submission);
    };

    const columns1 =[
        {
            width: 300,
            Header: "Edit",
            Cell: ({ cell }) => {
                 // debugger
                return (
                    <Tooltip placement="top" overlay={"View/Edit"}>
                        <button className={ "btn btn-info btn-sm m-0 mr-2 pt-1 pb-1 pl-2 pr-1"} onClick={() => redirect("/s/"+cell.row.original.qr_id)}>
                            <FontAwesomeIcon icon={faEye}/>
                        </button>
                    </Tooltip>
                )
            }
        },{
            Header: 'ID',
            accessor: 'qr_id',
            width: 100
        },{
            Header: 'By',
            accessor: 'u_first'
        },{
            Header: 'Created',
            accessor: 'note_created_display'
        },{
            Header: 'Note',
            accessor: 'n_note_excerpt'
        }
    ];

    const tableStructure= {
        tableHeaderData: columns1
    };

    return (

        <Panel xl={12} lg={12} md={12} title={title??"Notes"} subhead={subhead??"Submission notes"} refreshFunction={fetchData}>
            <div>
                {isTableLoaded &&
                    <DataReactTable reactTableData={ tableStructure } notes={notes}/>
                }
            </div>
        </Panel>

    );
};

export default connect(state => ({
    venues:state.venues.venues,
    submissions:state.submissions.submissions
}))(SubmissionsTable);
