import React, { useState } from 'react';
import { Card, CardBody, Col, Button } from 'reactstrap';
import ModalChangeTier from "./ModalChangeTier";

const VenueTier = ({ venueInfo }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <Col md={12} xl={3} lg={6} xs={12}>
      <Card>
        <CardBody className="dashboard__card-widget p-3" >
          <div className="card__title">
            <h5 className="bold-text">Venue Tier</h5>
          </div>
          <div className="card__body">
              <h3>{ venueInfo.vti_name }</h3>
              <ModalChangeTier currentTier={venueInfo}/>
          </div>


        </CardBody>
      </Card>
    </Col>
  );
};

export default VenueTier;
