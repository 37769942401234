import React, { useEffect,useState } from 'react';
import { connect } from 'react-redux';
import ModelUser from '../../../../../../Models/User';
import {Alert, Button, ButtonToolbar, Modal, Form, FormGroup, Label, Input, FormText, Col, Row} from 'reactstrap';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope, faEnvelopeOpenText, faMailBulk, faPhone, faPhoneSlash} from "@fortawesome/free-solid-svg-icons";

const ModalViewEditUser = ({dispatch, rtl,user}) => {
    const [modal, setModal] = useState(false);
    const [phoneChecked, setPhoneChecked] = useState(false);
    const [emailChecked, setEmailChecked] = useState(false);
    const [smsChecked, setSmsChecked] = useState(false);
    const [errorMsg,setError] = useState(false);
    const [successMsg,setSuccess] = useState(false);
    const mu = new ModelUser();

    useEffect(() => {
        toggle().then(()=>{
            let vef = document.getElementById('viewEditUserForm');
            Object.keys(user).forEach(key => {
                vef.querySelectorAll('input[name='+key+']').forEach((el)=>{
                    el.value = user[key];
                });
                vef.querySelectorAll('textarea[name='+key+']').forEach((el)=>{
                    el.value = user[key];
                });
            });
            setPhoneChecked((user.ud_phone==1)?true:false);
            setEmailChecked((user.ud_email==1)?true:false);
            setSmsChecked((user.ud_sms==1)?true:false);

        });
    },[user]);

    const toggle = async () => {
        if(modal===false){
            setError(false);
            setSuccess(false);

        }

        await setModal(prevState => !prevState);
    };

    const handleSubmit= (form) =>{
        form.preventDefault();
        if(user.v_id.length===0)
            alert("venue not found");
        let formData = new FormData(document.getElementById('viewEditUserForm'));
        formData.append('u_id',user.u_id);
        formData.append('v_id',user.v_id);

        mu.updateUser(formData).then(response => {
            if (response.data.status === 'failed') {
                setError(response.data.msg);
                setSuccess(false);
                return false;
            }

            setError(false);
            setSuccess(response.data.msg);

            // GET THE VENUE MEMBERS AGAIN - MIGHT BE THE WHOLE VENUE DATA
            // mu.getUsers().then(response=>{
            //     if (!response) {
            //         alert('failed to load users');
            //         return false;
            //     }
            //     return true;
            // });
        });
    };

    return (
        <div>
            <Modal isOpen={modal} toggle={toggle} className={`modal-dialog--primary modal-dialog--header`} modalClassName={`${rtl.direction}-support`} size="lg">
                <div className="modal__header">
                    <button
                        className="lnr lnr-cross modal__close-btn"
                        aria-label="modal__close-btn"
                        type="button"
                        onClick={toggle}
                    />
                    <h4 className="text-modal  modal__title">View/Edit User</h4>
                </div>

                <div className="modal__body">
                    <Alert
                        color="danger"
                        isOpen={!!errorMsg}
                    >
                        {errorMsg}
                    </Alert>
                    <Alert
                        color="success"
                        isOpen={!!successMsg}
                    >
                        {successMsg}
                    </Alert>

                    <Row>
                        <Col xs={12}>

                                <h4>Do Not Disturb Settings</h4>
                                <p>These are used to identify whether a user can or cannot be contacted </p>
                                <hr/>
                            <form id="viewEditUserForm" className="form--horizontal form" onSubmit={handleSubmit}>
                                <Col xs={12}>
                                    <div className="form-check">
                                        <input className="form-check-input" type="hidden" value="0" name="ud_phone"/>
                                        <input className="form-check-input" type="checkbox" value="1" name="ud_phone"
                                               checked={phoneChecked} onChange={() => setPhoneChecked(!phoneChecked)}/>
                                        <label className={`checkbox-btn__label form-check-label btn-sm pt-1 pb-1 pl-2 ${phoneChecked ? 'btn-danger' : 'btn-info'}`} htmlFor="venue_types_checkboxes_wrapper">
                                            <FontAwesomeIcon icon={phoneChecked ? faPhoneSlash : faPhone} /> Phone
                                        </label>
                                    </div>

                                    <div className="form-check mt-3">
                                        <input className="form-check-input" type="hidden" value="0" name="ud_email"/>
                                        <input className="form-check-input" type="checkbox" value="1" name="ud_email"
                                               checked={emailChecked} onChange={() => setEmailChecked(!emailChecked)}/>
                                        <label className={`checkbox-btn__label form-check-label btn-sm pt-1 pb-1 pl-2 ${emailChecked ? 'btn-danger' : 'btn-info'}`} htmlFor="venue_types_checkboxes_wrapper">
                                            <FontAwesomeIcon icon={emailChecked ? faPhoneSlash : faEnvelope} /> E-Mail
                                        </label>
                                    </div>

                                    <div className="form-check mt-3">
                                        <input className="form-check-input" type="hidden" value="0" name="ud_sms"/>
                                        <input className="form-check-input" type="checkbox" value="1" name="ud_sms"
                                               checked={smsChecked} onChange={() => setSmsChecked(!smsChecked)}/>
                                        <label className={`checkbox-btn__label form-check-label btn-sm pt-1 pb-1 pl-2 ${smsChecked ? 'btn-danger' : 'btn-info'}`} htmlFor="venue_types_checkboxes_wrapper">
                                            <FontAwesomeIcon icon={smsChecked ? faPhoneSlash : faEnvelopeOpenText} /> SMS
                                        </label>
                                    </div>

                                </Col>
                                <Col xs={12} className="text-right">
                                    <button className="btn btn-primary btn-sm" type="submit">Update</button>
                                </Col>
                            </form>
                        </Col>
                    </Row>
                    <Row className="mt-4">
                        <Col xs={12}>
                            <h4>User Account</h4>
                            <hr/>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            Coming Soon
                        </Col>
                    </Row>
                    <div className="row mt-3">
                        <div className={'col-12 text-right'}>
                            <button className="btn btn-danger btn-sm" type="button" onClick={toggle}>Close</button>
                        </div>
                    </div>

                </div>

            </Modal>
        </div>
    );
};

export default connect(state => ({
    rtl: state.rtl
}))(ModalViewEditUser);