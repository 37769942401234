import React from 'react';
import PropTypes from 'prop-types';
import SidebarLink from './SidebarLink';
import SidebarCategory from './SidebarCategory';
import {connect} from "react-redux";
import { faHotel,faLayerGroup } from '@fortawesome/free-solid-svg-icons';
import Submissions from "../../Submissions";


const SidebarContent = ({
  onClick,
  changeToDark,
  changeToLight,
  sidebarCollapse,

  showVenueMenu,
  venueData
}) => {

  const venueId = (typeof venueData === "undefined") ? "" : venueData.v_id;

  return (
      <div className="sidebar__content">
          <ul className="sidebar__block">
              <SidebarLink title="Dashboard" icon="home" route="/dashboard" onClick={onClick} />
              <SidebarLink title="Venues" icon={faHotel} route="/venues" onClick={onClick} />
              <SidebarLink title="Users" icon="user" route="/users" onClick={onClick} />
              <SidebarLink title="Submissions" icon="file" route="/Submissions" onClick={onClick} />
              <SidebarLink title="Tiers" icon={faLayerGroup} route="/dashboard" onClick={onClick} />
              <SidebarLink title="Reporting" icon="archive" route="/reporting" onClick={onClick} />
              {/*<SidebarCategory title="Tables" icon="list" isNew sidebarCollapse={sidebarCollapse}>*/}
              {/*<SidebarLink title="Basic tables" route="/tables/basic_tables" onClick={onClick} />*/}
              {/*<SidebarLink title="Data table" newLink route="/tables/data_table" onClick={onClick} />*/}
              {/*<SidebarLink title="Drag & Drop table" newLink route="/tables/dnd_table" onClick={onClick} />*/}
              {/*<SidebarLink title="Editable table" route="/tables/editable_table" onClick={onClick} />*/}
              {/*<SidebarLink*/}
              {/*title="Width resizable table"*/}
              {/*newLink*/}
              {/*route="/tables/resizable_table"*/}
              {/*onClick={onClick}*/}
              {/*/>*/}
              {/*</SidebarCategory>*/}
              {/*<SidebarLink title="Log In" icon="exit" route="/login" onClick={onClick} />*/}
              {/*<SidebarCategory title="Layout" icon="layers" sidebarCollapse={sidebarCollapse}>*/}
                  {/*<button type="button" className="sidebar__link" onClick={changeToLight}>*/}
                      {/*<p className="sidebar__link-title">Light Theme</p>*/}
                  {/*</button>*/}
                  {/*<button type="button" className="sidebar__link" onClick={changeToDark}>*/}
                      {/*<p className="sidebar__link-title">Dark Theme</p>*/}
                  {/*</button>*/}
              {/*</SidebarCategory>*/}
          </ul>
          {showVenueMenu &&
              <ul className="sidebar__block">
                  <SidebarLink icon="home" title="Home" route={"/venue/"+venueId} onClick={onClick} />
                  <SidebarLink icon="pencil-alt" title="Edit" route={"/edit/"+venueId} onClick={onClick} />
                  <SidebarLink icon="image" title="Photos" route={"/photos/"+venueId} onClick={onClick} />
              </ul>
          }

      </div>
  )
};

SidebarContent.propTypes = {
  changeToDark: PropTypes.func.isRequired,
  changeToLight: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  sidebarCollapse: PropTypes.bool,
};

SidebarContent.defaultProps = {
  sidebarCollapse: false,
};

export default connect(state => ({
    venueData:state.venue.venueData,
    showVenueMenu:state.venue.showVenueMenu
}))(SidebarContent);
