import { handleActions } from 'redux-actions';
import {
    setUser
} from '../actions/userActions';

const defaultState = {
    user:null
};

export default handleActions(
  {
    [setUser](state, { payload }) {
        return {...state, user: payload};
    }
  },
  defaultState,
);
