import React,{useState,useEffect} from "react";
import {connect} from "react-redux";
import ModelVenue from '../../../../Models/Venue';
import {showNotification} from "../../../../shared/components/Notification";

const Amenities = ({dispatch,venueData,theme,rtl}) => {
    const [amenities,setAmenities] = useState(null);
    const [loaded,setLoaded] = useState(false);

    const vm = new ModelVenue();


    useEffect(() =>{

        vm.getAvailableAmenities(venueData.v_id).then(async response => {
            if(response.data.status === 'failed'){
                showNotification(theme,'danger',rtl,'Get Amenities',response.data.msg);
                return;
            }

            await setAmenities(response.data.data.amenities);
            setLoaded(true);
            // let amenitiesContainer = document.getElementById("amenitiesContainer");

            // amenitiesContainer.innerHTML = "";
            // response.data.data.amenities.forEach(function(item){
            //
            //     let el= document.createElement('div');
            //     el.setAttribute('class',"form-check");
            //     el.innerHTML = `<input type="checkbox" class="form-check-input" data-venue-amenity-id="`+item.va_id+`" name="amenities[]" value="`+item.va_id+`" id="amenities`+item.va_id+`">
            //         <label class="form-check-label checkbox-btn__label" for="amenities`+item.va_id+`">`+item.va_name+`</label>`;
            //     amenitiesContainer.insertBefore(el, amenitiesContainer.firstChild);
            // });




        });
    },[venueData]);

    useEffect(()=> {
        if(!loaded)
            return;

        vm.getVenueAmenities(venueData.v_id).then(response=>{
            if(response.data.status === 'failed'){
                showNotification(theme,'danger',rtl,'Get Amenities',response.data.msg);
                return;
            }

            if(response.data.data.amenities.length === 0)
                return;
            response.data.data.amenities.forEach((am)=>{
                // document.querySelector("[data-venue-amenity-id="+am.va_id+"]").checked = true;
                document.querySelector("[data-venue-amenity-id='"+am.va_id+"']").checked = true;
            });
        });
    },[loaded]);

    const updateVenueAmenities = (e) =>{
        e.preventDefault();
        let formData = new FormData(document.getElementById('updateVenueAmenities'));
        // venue type ids
        let vt_ids = "";
        let comma = "";
        if(document.querySelectorAll('input[type=checkbox][name*=vt_ids]:checked').length > 0){
            document.querySelectorAll('input[type=checkbox][name*=vt_ids]:checked').forEach(function(x){
                vt_ids += comma + x.value;
                comma = ",";
            });
        }
        formData.append('vt_ids',vt_ids);

        let v_outdoor_space_available = "N";
        if(document.querySelectorAll('input[type=checkbox][name=v_outdoor_space_available]:checked').length > 0){
            v_outdoor_space_available = 'Y';
        }
        formData.append('v_outdoor_space_available',v_outdoor_space_available);

        vm.updateVenueAmenities(venueData.v_id,formData).then((response)=>{
            if(response.data.status==='failed'){
                showNotification(theme,'danger',rtl,'Get Images',response.data.msg);
                return;
            }

            showNotification(theme,'success',rtl,'Update Venue Amenities',response.data.msg);
            return;
        });

    };

    return (
        <>
            <form id="updateVenueAmenities" onSubmit={updateVenueAmenities}>
                <div id='amenitiesContainer'>
                    {amenities !== null &&
                        amenities.map(a => (
                            <div className='form-check' key={a.va_id}>
                            <input type="checkbox" className="form-check-input" data-venue-amenity-id={a.va_id} name="amenities[]" value={a.va_id} id={"amenities"+a.va_id} />
                            <label className="form-check-label checkbox-btn__label" htmlFor={"amenities"+a.va_id}>{a.va_name}</label>
                            </div>
                        ))
                    }

                </div>


                <button type={'submit'} className="btn btn-primary">Update</button>
            </form>
        </>
    );
}

export default connect(state => ({
    rtl: state.rtl,
    theme: state.theme,
    venueData:state.venue.venueData,
    showVenueMenu:state.venue.showVenueMenu
}))(Amenities);