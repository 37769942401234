import React,{ useEffect,useState } from 'react';
import Panel from "../Panel";
import DataReactTable from "./DataReactTable";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {connect} from "react-redux";
import ModelSubmissions from '../../../Models/Submissions';
import {Link, useHistory} from "react-router-dom";
import {faExternalLinkAlt, faEye, faHotel, faPen} from "@fortawesome/free-solid-svg-icons";
import Tooltip from "rc-tooltip";
import DataTable from "../DataTable";

const UsersTable = ({ dispatch, title, limit}) => {

    const um = new ModelSubmissions();
    const history = useHistory();

    const redirect = (link) =>{
        history.push(link);
    };

    const columns =[
        {
            width: 300,
            Header: "Edit",
            Cell: ({ cell }) => {
                 // debugger
                return (
                    <>
                        <Tooltip placement="top" overlay={"View/Edit"}>
                            <button type={"button"} className={ "btn btn-info btn-sm m-0 mr-2 pt-1 pb-1 pl-2 pr-1"} onClick={() => redirect("/u/"+cell.row.original.u_id)}>
                                <FontAwesomeIcon icon={faPen}/>
                            </button>
                        </Tooltip>
                        <Tooltip placement="top" overlay={"View/Edit New Tab"}>
                            <Link
                                to={"/u/" + cell.row.original.u_id}
                                target="_blank"
                            >
                                <button type={"button"} className={ "btn btn-info btn-sm m-0 mr-2 pt-1 pb-1 pl-2 pr-1"}>
                                    <FontAwesomeIcon icon={faExternalLinkAlt} />
                                </button>
                            </Link>
                        </Tooltip>
                    </>
                )
            }
        },{
            Header: 'ID',
            accessor: 'u_id'
        },{
            width: 300,
            Header: "Name",
            accessor: "name"
        },{
            Header: 'Created',
            accessor: 'u_registration_date_display',
            width: 150
        },{
            Header: 'Stripe ID',
            accessor: 'u_stripe_cust_id'
        }
    ];

    const tableStructure= {
        tableHeaderData: columns
    };

    return (

        <Panel xl={12} lg={12} md={12} title={title??"Users"} subhead="Table of Users">
            <div>
                <DataTable columns={columns} endpoint={"/urs/getUsersForDataTable"}/>
            </div>
        </Panel>

    );
};

export default connect(state => ({
    venues:state.venues.venues,
    submissions:state.submissions.submissions
}))(UsersTable);
