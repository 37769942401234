import React, { useEffect,useState } from 'react';
import { connect } from 'react-redux';
import ModelVenues from '../../../../Models/Venues';
import { Button, ButtonToolbar, Modal,Form,FormGroup,Label,Input,FormText } from 'reactstrap';
import { showNotification } from '../../../../shared/components/Notification';

const ModalChangeStatus = ({dispatch, venueData, rtl, theme}) => {
    const [modal, setModal] = useState(false);
    // const [status,setStatus] = useState(null);
    const mvs = new ModelVenues();

    useEffect(async () => {
        // setStatus(venueData.v_active);
    },[]);

    const toggle = async () => {
        await setModal(prevState => !prevState);
    };

    const createBlankVenue = () => {
        if(!confirm("Are you sure you want to create a new BLANK venue?"))
            return;
        mvs.createBlankVenue().then(async response => {
            await setModal(prevState => !prevState);
            if (response.data.status === 'failed') {
                showNotification(theme,'primary',rtl,'Add Blank Venue',response.data.msg);
                return false;
            }
            showNotification(theme,'primary',rtl,'Add Blank Venue',response.data.msg);
            return;
        });
    };

    const handleSubmit= (form) =>{
        if(!confirm("Are you sure you want to create a new venue?"))
            return;
        form.preventDefault();

        let googlePlaceId =document.getElementById("mcvGooglePlaceId").value;

        mvs.createVenueByGooglePlaceId(googlePlaceId).then(response => {
            if (response.data.status === 'failed') {
                showNotification(theme,'primary',rtl,'Add Venue From Google',response.data.msg);
                return false;
            }

            mvs.getVenues().then(async response=>{
                await setModal(prevState => !prevState);
                return;
            });
            showNotification(theme,'primary',rtl,'Add Venue From Google',response.data.msg);
        });
    };

    return (
        <div>
            <Button color={"info"} className={"btn-sm btn-block mb-0 mt-2"} onClick={toggle}>Create</Button>

            <Modal isOpen={modal} toggle={toggle} className={`modal-dialog--primary modal-dialog--header`} modalClassName={`${rtl.direction}-support`} size="sm">
                <div className="modal__header">
                    <button
                        className="lnr lnr-cross modal__close-btn"
                        aria-label="modal__close-btn"
                        type="button"
                        onClick={toggle}
                    />
                    <h4 className="text-modal  modal__title">Create</h4>
                </div>
                <div className="modal__body">
                    <Form onSubmit={handleSubmit}>

                        <FormGroup className="mb-3">
                            <Label>Google Place Id</Label>
                            <input id={"mcvGooglePlaceId"} className={"form-select form-control"} name={"mcvGooglePlaceId"}/>
                            <small>To get the google place id of the venue go to: <a href="https://developers.google.com/maps/documentation/javascript/examples/places-placeid-finder" target="_blank">Place Id Finder</a></small>
                        </FormGroup>

                        <ButtonToolbar className="modal__footer">
                            <Button className="modal_ok btn-info" onClick={createBlankVenue}>Create Blank</Button>{' '}
                            <Button className="modal_ok btn-primary" type={"submit"}>Create</Button>{' '}
                            <Button className="modal_cancel btn-danger" onClick={toggle}>Cancel</Button>
                        </ButtonToolbar>
                    </Form>
                </div>

            </Modal>
        </div>
    );
};

export default connect(state => ({
    rtl: state.rtl,
    theme: state.theme,
    dateRangeEnding: state.venue.dateRangeEnding,
    dateRangeStarting:state.venue.dateRangeStarting,
    venueData:state.venue.venueData
}))(ModalChangeStatus);